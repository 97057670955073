import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const DoesItEverGetBetter = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("doesItEverGetBetter_title")}</span>
        <span className="info">
          {translate("doesItEverGetBetter_info")}, 4’23’’
          <br />
          2018
        </span>
        <span className="description">
          {translate("doesItEverGetBetter_description")}
        </span>
      </section>
      <img
        src={"/images/does_it_ever_get_better2.png"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/does_it_ever_get_better1.png"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default DoesItEverGetBetter;
