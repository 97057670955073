import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import "./pages.css";

const Cv = () => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="page-sub">
      <section className="content">
        <span className="cv-header">{translate("exhibitionsHeader")}:</span>
        <span className="year">{translate("exhibitions_2020_year")}</span>
        <div className="cv-item">
          <p className="exhibition">{translate("exhibitions_2020_desc1")}</p>
          <p className="exhibition">{translate("exhibitions_2020_desc2")}</p>
          <p className="exhibition">{translate("exhibitions_2020_desc3")}</p>
        </div>
        <span className="year">{translate("exhibitions_2019_year")}</span>
        <div className="cv-item">
          <p className="exhibition">{translate("exhibitions_2019_desc1")}</p>
          <p className="exhibition">{translate("exhibitions_2019_desc2")}</p>
        </div>
        <span className="year">{translate("exhibitions_2018_year")}</span>
        <div className="cv-item">
          <p className="exhibition">{translate("exhibitions_2018_desc1")}</p>
          <p className="exhibition">{translate("exhibitions_2018_desc2")}</p>
          <p className="exhibition">{translate("exhibitions_2018_desc3")}</p>
        </div>
        <span className="year">{translate("exhibitions_2017_year")}</span>
        <div className="cv-item">
          <p className="exhibition">{translate("exhibitions_2017_desc1")}</p>
        </div>
        <br></br>
        <br></br>
        <span className="cv-header">{translate("awardsHeader")}:</span>
        <div className="cv-item">
          <p className="award">{translate("awards1")}</p>
          <p className="award">{translate("awards2")}</p>
          <p className="award">{translate("awards3")}</p>
        </div>
      </section>
    </div>
  );
};

export default Cv;
