import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const Gone = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("gone_title")}</span>
        <span className="info">
          {translate("gone_info")}, 3’36’’
          <br />
          2018
        </span>
        <span className="description">{translate("gone_description")}</span>
      </section>
      <img
        src={"/images/gone1.png"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/gone2.png"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default Gone;
