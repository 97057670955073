import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const ThereIsNothing = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("thereIsNothing_title")}</span>
        <span className="info">
          {translate("thereIsNothing_info")}, 6’41’’
          <br />
          2019
        </span>
        <span className="description">{translate("thereIsNothing_desc")}</span>
      </section>
      <img
        src={"/images/there_is_nothing1.png"}
        alt="wall fungus animation screenshot 1"
      />
      <img
        src={"/images/there_is_nothing2.png"}
        alt="wall fungus animation screenshot 2"
      />
    </article>
  );
};

export default ThereIsNothing;
