import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const ToTheMountains = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("toTheMountains_title")}</span>
        <span className="info">
          {translate("toTheMountains_info")}
          <br />
          2021
        </span>
        <span className="info">
          {translate("toTheMountains_info_participants1")}
        </span>
        <span className="info">
          {translate("toTheMountains_info_participants2")}
        </span>
        <span className="description">
          {translate("toTheMountains_description1")}
        </span>
        <span className="description">
          {translate("toTheMountains_description2")}
        </span>
        <span className="info" style={{ width: "100%", textAlign: "right" }}>
          Weronika Elertowska
        </span>
        <audio controls src="/audio/to-the-mountains.mp3" typeof="audio/mpeg" style={{width: '100%', marginBottom: '5rem', marginTop: '5rem'}}/>
      </section>
      <img
        src={"/images/mountains_1.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/mountains_2.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/mountains_3.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/mountains_4.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default ToTheMountains;
