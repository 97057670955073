import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const ThePantry = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("pantry_title")}</span>
        <span className="info">
          {translate("pantry_info")}
          <br />
          2019
        </span>
        <span className="description">{translate("pantry_description1")}</span>
        <span className="description">{translate("pantry_description2")}</span>
      </section>
      <img
        src={"/images/pantry_1.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/pantry_2.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <div
        style={{
          display: "flex",
          width: "calc(100% - 4.75rem)",
          maxWidth: "62rem",
          minWidth: "32rem - 5rem",
          maxHeight: "46rem",
          justifyContent: "space-between",
          height: "100%",
          margin: "0 auto"
        }}
      >
        <img
          src={"/images/pantry_3.jpg"}
          alt="wooden plank painted pink with nails"
          style={{
            width: "50%",
            height: "100%",
            maxWidth: "300px",
          }}
        />
        <img
          src={"/images/pantry_4.jpg"}
          alt="wooden plank painted pink with nails"
          style={{
            width: "50%",
            height: "100%",
            maxWidth: "300px",
          }}
        />
      </div>
      <img
        src={"/images/pantry_5.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default ThePantry;
