import React from "react";
const Home = () => {
  return (
    <img
      src={"/images/domie_07.jpg"}
      alt="art gallery exhibition with framed photos on walls and red carpet"
    />
  );
};

export default Home;
