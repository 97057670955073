import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const Rectification = () => {
  const { langCode, translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("rectification_title")}</span>
        <span className="info">
          {translate("rectification_info")}
          <br />
          {translate("rectification_info_participants")}          
          <br />
          2019
        </span>
        {langCode === 'pl' && (
          <>
            <span className="description">
            {translate("rectification_description1")}
            </span>
            <span className="description">
              {translate("rectification_description2")}
            </span>
            <span className="description">
              {translate("rectification_description3")}
            </span>
            <span className="description">
              {translate("rectification_description4")}
            </span>
            <span className="description">
              {translate("rectification_description5")}
            </span>
            <span className="description">
              {translate("rectification_description6")}
            </span>
            <span className="description">
              {translate("rectification_description7")}
            </span>
            <span className="description">
              {translate("rectification_description8")}
            </span>
            <span className="description">
              {translate("rectification_description9")}
            </span>
            <span className="description">
              {translate("rectification_description10")}
            </span>
            <span className="description">
              {translate("rectification_description11")}
            </span>
            <span className="description">
              {translate("rectification_description12")}
            </span>
            <span className="description">
              {translate("rectification_description13")}
            </span>
            <span className="description-info">
              {translate("rectification_description14")}
            </span>
          </>
        )}        
      </section>
      <img
        src={"/images/DSC_0480_fot_klaudyna_schubert.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/DSC_1171 M.Zakrzewski (s)-min.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/sprostowanie.png"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/DSC_1183 M.Zakrzewski (s)-min.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/DSC_1169 M.Zakrzewski (s)-min.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/DSC_1163 M.Zakrzewski (s)-min.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default Rectification;
