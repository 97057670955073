import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const TheFeast = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("feast_title")}</span>
        <span className="info">
          {translate("feast_info")}
          <br />
          2019
        </span>
        <span className="description">{translate("feast_description1")}</span>
        <span className="description">{translate("feast_description2")}</span>
        <span className="description">{translate("feast_description3")}</span>
        <span className="description-quote">
          {translate("feast_description4Quote")}
        </span>
        <span className="description-author">
          {translate("feast_description4Author")}
        </span>
        <span className="description">{translate("feast_description5")}</span>
      </section>
      <img
        src={"/images/uczta1.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/uczta2.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/uczta3.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/uczta4.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/uczta5.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <div
        style={{
          display: "flex",
          width: "calc(100% - 4.75rem)",
          maxWidth: "62rem",
          minWidth: "32rem - 5rem",
          maxHeight: "46rem",
          justifyContent: "space-between",
          height: "100%",
          margin: "0 auto"
        }}
      >
        <img
          src={"/images/uczta6.jpg"}
          alt="wooden plank painted pink with nails"
          style={{
            width: "50%",
            height: "100%",
            maxWidth: "300px"
          }}
        />
        <img
          src={"/images/uczta7.jpg"}
          alt="wooden plank painted pink with nails"
          style={{
            width: "50%",
            height: "100%",
            maxWidth: "300px"
          }}
        />
      </div>
      <img
        src={"/images/uczta8.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default TheFeast;
