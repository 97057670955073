import React, { useContext } from "react";

import { I18nContext } from "../../i18n";

import "./languageSelect.css";

const LanguageSelect = () => {
  const { langCode, dispatch } = useContext(I18nContext);

  // const onLanguageSelect = e =>
  //   dispatch({ type: "setLanguage", payload: e.target.value });

  const onLanguageSelect = selectedLang =>
  dispatch({ type: "setLanguage", payload: selectedLang });

  return (
    <div>
      <button className={langCode === 'pl' ? "lang-button selected" : "lang-button"} onClick={() => onLanguageSelect('pl')}>
        <span>PL</span>
      </button>
      <button className={langCode === 'en' ? "lang-button selected" : "lang-button"} onClick={() => onLanguageSelect('en')}>
        <span>EN</span>
      </button>
    </div>
    // <select onChange={onLanguageSelect}>
    //   {renderOption("pl")}
    //   {renderOption("en")}
    // </select>
  );
};

export default LanguageSelect;
