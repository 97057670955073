import React from "react";
import { Link } from "react-router-dom";
import "./header.css";
import LanguageSelect from "../LanguageSelect/LanguageSelect";

const Header = () => (
  <header className="header-container">
    <div className="header-content">
      <span>
        <Link to="/" className="homepage-link">
          adelina cimochowicz
        </Link>
      </span>
      <LanguageSelect />
    </div>
  </header>
);

export default Header;
