import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const HowToAvoidEvictionInstruction = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("howToAvoidEviction_title")}</span>
        <span className="info">
          {translate("howToAvoidEviction_info")}
          <br />
          2019
        </span>
        <span className="description">
          {translate("howToAvoidEviction_description1")}
        </span>
        <span className="description">
          {translate("howToAvoidEviction_description2")}
        </span>
        <span className="description">
          {translate("howToAvoidEviction_description3")}
        </span>
      </section>
      <img
        src={"/images/how_to_avoid_eviction_1.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default HowToAvoidEvictionInstruction;
