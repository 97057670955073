import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const ImNotGoingAnywhere = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("imNotGoingAnywhere_title")}</span>
        <span className="info">
          {translate("imNotGoingAnywhere_info")}
          <br />
          2021
        </span>
        <span className="description">
          {translate("imNotGoingAnywhere_description1")}
        </span>
        <span className="description">
          {translate("imNotGoingAnywhere_description2")}
        </span>
        <span className="info" style={{ width: "100%", textAlign: "right" }}>
          Tomek Pawłowski Jarmołajew
        </span>
      </section>
      <img
        src={"/images/im_not_going_anywhere1.png"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/im_not_going_anywhere2.png"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default ImNotGoingAnywhere;
