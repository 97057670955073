import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { I18nContext } from "../../i18n";
import "./nav.css";

const Nav = props => {
  const { translate } = useContext(I18nContext);

  return (
    <nav className="nav-container">
      <ul className="nav-list">
        <li className="nav-list-item">
          <Link
            to="/works"
            style={{
              textDecoration: `${
                props.location === "/works" ? "underline" : "none"
              }`
            }}
          >
            {translate("Works")}
          </Link>
        </li>
        <li className="nav-list-item">
          <Link
            to="/cv"
            style={{
              textDecoration: `${
                props.location === "/cv" ? "underline" : "none"
              }`
            }}
          >
            cv
          </Link>
        </li>
        <li className="nav-list-item">
          <Link
            to="/contact"
            style={{
              textDecoration: `${
                props.location === "/contact" ? "underline" : "none"
              }`
            }}
          >
            {translate("Contact")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
