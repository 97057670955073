import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { I18nContext } from "../../i18n";

const WorksAll = () => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="page-sub">
      <section className="content">
        <ul className="works-list">
          <li>
            <Link to="/works/after-you-my-cold-aunt">
              {translate("afterYouMyColdAunt_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/to-the-mountains">
              {translate("toTheMountains_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/im-not-going-anywhere">
              {translate("imNotGoingAnywhere_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/loss-utrata">
              {translate("lossUtrata_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/how-to-avoid-eviction-instruction">
              {translate("howToAvoidEviction_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/there-is-nothing">
              {translate("thereIsNothing_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/rectification">
              {translate("rectification_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/the-feast">{translate("feast_title")}</Link>
          </li>
          <li>
            <Link to="/works/the-pantry">{translate("pantry_title")}</Link>
          </li>
          <li>
            <Link to="/works/natural-state">
              {translate("naturalState_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/does-it-ever-get-better">
              {translate("doesItEverGetBetter_title")}
            </Link>
          </li>
          <li>
            <Link to="/works/gone">{translate("gone_title")}</Link>
          </li>
          <li>
            <Link to="/works/museum-of-neurosis">
              {translate("museumOfNeurosis_title")}
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default WorksAll;
