import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./components/Header/Header";
import Nav from "./components/Nav/Nav";
import Home from "./components/Pages/Home";
import WorksAll from "./components/Pages/WorksAll";
import ThereIsNothing from "./components/Pages/Works/ThereIsNothing";
import Rectification from "./components/Pages/Works/Rectification";
import TheFeast from "./components/Pages/Works/TheFeast";
import Gone from "./components/Pages/Works/Gone";
import NaturalState from "./components/Pages/Works/NaturalState";
import DoesItEverGetBetter from "./components/Pages/Works/DoesItEverGetBetter";
import MuseumOfNeurosis from "./components/Pages/Works/MuseumOfNeurosis";
import Cv from "./components/Pages/Cv";
import Contact from "./components/Pages/Contact";
import ThePantry from "./components/Pages/Works/ThePantry";
import HowToAvoidEvictionInstruction from "./components/Pages/Works/HowToAvoidEvictionInstruction";
import LossUtrata from "./components/Pages/Works/LossUtrata";
import ToTheMountains from "./components/Pages/Works/ToTheMountains";
import ImNotGoingAnywhere from "./components/Pages/Works/ImNotGoingAnywhere";
import AfterYouMyColdAunt from "./components/Pages/Works/AfterYouMyColdAunt";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <Nav></Nav>
        <div className="page">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/works" component={WorksAll} />
            <Route
              exact
              path="/works/after-you-my-cold-aunt"
              component={AfterYouMyColdAunt}
            />
            <Route
              exact
              path="/works/to-the-mountains"
              component={ToTheMountains}
            />
            <Route
              exact
              path="/works/im-not-going-anywhere"
              component={ImNotGoingAnywhere}
            />
            <Route
              exact
              path="/works/loss-utrata"
              component={LossUtrata}
            />
            <Route
              exact
              path="/works/how-to-avoid-eviction-instruction"
              component={HowToAvoidEvictionInstruction}
            />
            <Route
              exact
              path="/works/rectification"
              component={Rectification}
            />
            <Route
              exact
              path="/works/there-is-nothing"
              component={ThereIsNothing}
            />
            <Route exact path="/works/the-feast" component={TheFeast} />
            <Route exact path="/works/the-pantry" component={ThePantry} />
            <Route exact path="/works/gone" component={Gone} />
            <Route exact path="/works/natural-state" component={NaturalState} />
            <Route
              exact
              path="/works/does-it-ever-get-better"
              component={DoesItEverGetBetter}
            />
            <Route
              exact
              path="/works/museum-of-neurosis"
              component={MuseumOfNeurosis}
            />
            <Route exact path="/cv" component={Cv} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
