import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const MuseumOfNeurosis = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("museumOfNeurosis_title")}</span>
        <span className="info">{translate("museumOfNeurosis_info")}</span>
        <span className="description">
          {translate("museumOfNeurosis_description1")}
        </span>
        <span className="description">
          {translate("museumOfNeurosis_description2")}
        </span>
        <span className="description">
          {translate("museumOfNeurosis_description3")}
        </span>
        <span className="info" style={{ width: "100%", textAlign: "right" }}>
          Jakub Gawkowski
        </span>
      </section>

      <img
        src={"/images/muzeum_nerwicy4.jpg"}
        alt="wall fungus animation screenshot 1"
      />
      <img
        src={"/images/muzeum_nerwicy1.jpg"}
        alt="wall fungus animation screenshot 1"
      />
      <img
        src={"/images/muzeum_nerwicy2.jpg"}
        alt="wall fungus animation screenshot 1"
      />
      <img
        src={"/images/muzeum_nerwicy3.jpg"}
        alt="wall fungus animation screenshot 1"
      />
    </article>
  );
};

export default MuseumOfNeurosis;
