import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const AfterYouMyColdAunt = () => {
  const { translate, langCode } = useContext(I18nContext);

  console.log('langCode:');
  console.log(langCode);

  const getCast = () => {
    const castMembers = [];
    for(let step = 0; step < 13; step++) {
      console.log(translate(`afterYouMyColdAunt_cast${step+1}`));
      castMembers.push(
        <span className="description">
          {translate(`afterYouMyColdAunt_cast${step+1}`)}
        </span>
      )
    }

    return castMembers;
  }

  // var indents = [];
  // for (var i = 0; i < this.props.level; i++) {
  //   indents.push(<span className='indent' key={i}></span>);
  // }
  // return indents;

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("afterYouMyColdAunt_title")}</span>
        <span className="info">
          {translate("afterYouMyColdAunt_info")}
          <br />
          {translate("afterYouMyColdAunt_info2")}
          <br />
          2022
        </span>
        <span className="description">
          {translate("afterYouMyColdAunt_description1")}
        </span>
        <span className="description">
          {translate("afterYouMyColdAunt_description2")}
        </span>
        <br />
        {getCast()}
        <br />
        <span className="description">
          {translate("afterYouMyColdAunt_description3")}
        </span>

        <br/>

        <span className="description">
          {translate("afterYouMyColdAunt_reviews")}
        </span>
        <span className="reviewer">
          {translate("afterYouMyColdAunt_review1")}
        </span>
        <a className="review-link" href="https://www.dwutygodnik.com/artykul/9708-odkopac-ciotke.html?print=1" target="_blank" rel="noopener noreferrer">
          https://www.dwutygodnik.com/artykul/9708-odkopac-ciotke.html?print=1
        </a>
        <span className="reviewer">
          {translate("afterYouMyColdAunt_review2")}
        </span>
        <a className="review-link" href="https://www.dialog-pismo.pl/przedstawienia/jaskinie-darknessu-i-kesy-garderoby" target="_blank" rel="noopener noreferrer">
          https://www.dialog-pismo.pl/przedstawienia/jaskinie-darknessu-i-kesy-garderoby
        </a>
        <span className="reviewer">
          {translate("afterYouMyColdAunt_review3")}
        </span>
        <a className="review-link" href="https://didaskalia.pl/pl/artykul/co-sie-kryje-w-ciemnosci" target="_blank" rel="noopener noreferrer">
          https://didaskalia.pl/pl/artykul/co-sie-kryje-w-ciemnosci
        </a>
      </section>
      <img
        src={"/images/aunt_1.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/aunt_2.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/aunt_3.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/aunt_4.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/aunt_5.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/aunt_6.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default AfterYouMyColdAunt;
