import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const LossUtrata = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("lossUtrata_title")}</span>
        <span className="info">
          {translate("lossUtrata_info")}, 14’
          <br />
          2020
        </span>
        <span className="description">
          {translate("lossUtrata_description1")}
        </span>
        <span className="description">
          {translate("lossUtrata_description2")}
        </span>
        <span className="description">
          {translate("lossUtrata_description3")}
        </span>
        <span className="description">
          {translate("lossUtrata_description4")}
        </span>
      </section>
      <img
        src={"/images/utrata3.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/utrata2.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/utrata1.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/utrata4.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/utrata5.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/utrata7.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/utrata9.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default LossUtrata;
