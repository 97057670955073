import React from "react";
import "./pages.css";

const Contact = () => {
  return (
    <div className="page-sub">
      <section className="content">
        <span className="contact-item">
          <a href="mailto:a.cimochowicz@gmail.com">a.cimochowicz@gmail.com</a>
        </span>
        <span className="contact-item">+48 530 980 967</span>
        <span className="contact-item">
          <a
            href="https://www.instagram.com/adelinacimochowicz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            instagram
          </a>
        </span>
      </section>
    </div>
  );
};

export default Contact;
