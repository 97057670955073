import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import "./works.css";

const NaturalState = () => {
  const { translate } = useContext(I18nContext);

  return (
    <article className="page-sub">
      <section className="content">
        <span className="title">{translate("naturalState_title")}</span>
        <span className="info">
          {translate("naturalState_info")}, 6'
          <br />
          2018
        </span>
        <span className="description">
          {translate("naturalState_description")}
        </span>
        <span className="info" style={{ width: "100%", textAlign: "right" }}>
          Jakub Gawkowski
        </span>
      </section>
      <img
        src={"/images/stan_naturalny1.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/stan_naturalny2.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/stan_naturalny3.jpg"}
        alt="wooden plank painted pink with nails"
      />
      <img
        src={"/images/stan_naturalny4.jpg"}
        alt="wooden plank painted pink with nails"
      />
    </article>
  );
};

export default NaturalState;
